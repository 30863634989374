import { showSnackbarAction } from '../../store/snackbars/snackbarAction'
import { fetchBookingsAction } from '../../store/myTrips/myTripsAction'
import { useDispatch, useSelector } from 'react-redux'
import { getApiUrl } from '../../repositories/agent'
import { useIsMounted } from '../../utils/hooks.ts'
import { AuthUser, ReduxState } from '../../types'
import { useEffect, useState } from 'react'

// parent component is being wrongly rendered twice with full unmounting
// this causes PNR sync to be called twice
// this is a workaround to prevent the second call
let tsPNRSyncStarted = new Date('2000-01-01')

export const usePNRSync = (bookings: any[]) => {
  const [bookingsScanStatus, setBookingsScanStatus] = useState({})
  const [listReceived, setListReceived] = useState(false)
  const dispatch = useDispatch()
  const isMountedRef = useIsMounted()

  const accessToken = useSelector<ReduxState, string>((state) => state.auth.jwtToken)
  const user = useSelector<ReduxState, AuthUser>((state) => state.auth.user)

  const progress = (() => {
    const numBookings = Object.keys(bookingsScanStatus).length
    const numScanned = Object.values(bookingsScanStatus).filter((status) => status === 'synced').length

    return (listReceived ? 0.1 : 0) + (numBookings > 0 ? (numScanned / numBookings) * 0.9 : (listReceived ? 0.9 : 0))
  })();

  useEffect(() => {
    // see my comments at tsPNRSyncStarted variable init
    if (((new Date()).getTime() - tsPNRSyncStarted.getTime() < 1000) || !process.env.REACT_APP_PNR_SYNC) return

    tsPNRSyncStarted = new Date()

    const existingPnrs = bookings.map((b) => b.pnr)
    const newBookingsScanStatus = Object.fromEntries(existingPnrs.map((pnr) => [pnr, 'synced']))
    // TODO: find out where can 'uniqueId' come from
    const guessTheId = user.uniqueId || user.companyId
    const sse = new EventSource(
      getApiUrl('/v2/get-bookings-live')
      + '?t=' + accessToken
      + '&userExternalId=' + guessTheId
      + (process.env.REACT_APP_PNR_SYNC_FULL ? `&fullSync=true` : '')
    )

    sse.onmessage = (event) => {
      if (!isMountedRef.current) {
        // it should be safe to stop it completely
        sse.close()
        return
      }

      try {
        const data = JSON.parse(event.data)
        // console.log('SSE incoming message', data)

        switch (data.cmd) {
          case 'PNR_List':
            for (const pnr of data.pnrs) {
              newBookingsScanStatus[pnr] = existingPnrs.includes(pnr) ? 'sync-existing' : 'sync-new'
            }
            setBookingsScanStatus(newBookingsScanStatus)
            setListReceived(true)
            break

          case 'PNR_Retrieve':
            setBookingsScanStatus((prev) => ({
              ...prev,
              [data.pnr]: 'synced',
            }))
            break

          // DB synced -- re-fetch bookings from it
          case 'synced':
            void dispatch(fetchBookingsAction())
            break

          case 'error':
            // just reset them for now
            setBookingsScanStatus(Object.fromEntries(existingPnrs.map((pnr) => [pnr, 'synced'])))
            dispatch(showSnackbarAction('error', 'PNR sync failed', { x: 'right', y: 'top' }))
        }
      } catch {
        sse.close()
      }
    }
    sse.onerror = () => {
      sse.close()
    }
  }, [accessToken])

  return {
    progress,
    bookingsScanStatus,
  }
}
